import * as React from "react";
import { ChakraProvider, Box } from '@chakra-ui/react'
import HeroSection from "./components/HeroSection";
import EvolutionSection from "./components/EvolutionSection";
import ServicesSection from "./components/ServicesSection";
import CourtSupportSection from "./components/CourtSupportSection";
import Footer from "./components/Footer";
const App = () => {
  return (
    <ChakraProvider >
      <Box maxW={[1080,'100vw']} backgroundColor='#00153D' >
        <div>
          <HeroSection />
        </div>
        <div id="about">
          <EvolutionSection />
        </div>
        <div id="services">
          <ServicesSection />
        </div>
        <div id="more">
          <CourtSupportSection />
        </div>
      </Box> 
      <Footer />     
    </ChakraProvider>

  );
};
export default App;
