import React from "react";
import { Box, HStack, VStack, Text, Image } from "@chakra-ui/react";
import VegrevileEgg from "./assets/Vegreville_pysanka_August_2008.png";

const EvolutionSection = () => {
  return (
    <Box
      minHeight={["", "70vw"]}
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt={["3rem", ""]}
    >
      <HStack
        color="white"
        justifySelf="center"
        alignSelf="center"
        width={"80vw"}
        alignItems={["center", "center"]}
        justifyContent="center"
      >
        <VStack
          textAlign={["center", "flex-start"]}
          alignItems={["center", "flex-start"]}
        >
          <Text
            fontSize={["2rem", "3vw"]}
            pb={[2, 5]}
            pt={[2, 0]}
            fontWeight="bold"
          >
            Our Evolution
          </Text>
          <Text fontSize={["", "2vw"]} textAlign={["center", "start"]}>
            The CAV Association was spearheaded by Caroline Knowles in 1985 in
            response to the suicide death of a teenage neighbour. The Victim
            Service Unit was an expansion of CAV and became operational in 1998.
            Victim Services provincially was developed when the Victims Programs
            Assistance Act was enacted in 1991, beginning with only 14
            police-based Victim Service Units in Alberta. Currently, there are
            156 units across the province. The Crisis Association of Vegreville
            is a non-profit organization with volunteer board members.
          </Text>
        </VStack>
        <Image
          src={VegrevileEgg}
          display={["none", "inline"]}
          minWidth="40vw"
          p="2rem"
        />
      </HStack>
    </Box>
  );
};

export default EvolutionSection;
