import React from 'react';
import {Box, HStack} from '@chakra-ui/react';

const NaviBar = () => {
  return (
    <HStack display={['none','flex']} color='white' fontSize={['1rem','1.5vw']}flexDirection='row' width='30vw' p={5} justifyContent='space-between' justifySelf='end'>
          <Box _hover={{ textDecor:'underline'}}>
            <a href='#about'>About</a>
          </Box>
          <Box _hover={{ textDecor:'underline'}}>
            <a href='#services'>Services</a>
          </Box>
          <Box _hover={{ textDecor:'underline'}}>
            <a href='#team'>Team</a>
          </Box>
          <Box _hover={{ textDecor:'underline'}}>
            <a href='#more'>More</a>
          </Box>           
    </HStack>
  )
}

export default NaviBar