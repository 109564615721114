import React from "react";
import { Box, Text, VStack, Center, HStack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      display={["none", "flex"]}
      color={"white"}
      w={"100%"}
      bgColor={"black"}
      justifyContent={"center"}
      fontSize={"1.25vw"}
      minHeight={"fit-content"}
    >
      <Box
        h={"fit-content"}
        flexDir={["column", "row"]}
        m={"3rem !important"}
        display={"flex"}
        w={"80%"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <VStack alignContent={"flex-start"}>
          <Text fontSize={"2vw"}>Contact Us</Text>
          <Text>cavegreville@gmail.com</Text>
          <Text>780-603-7381</Text>
        </VStack>
        <VStack alignContent={"flex-start"}>
          <Text fontSize={"2vw"}>Social Media</Text>
          <Icon as={FaFacebookF} color={"white"} fontSize={"2vw"} />
        </VStack>
        <Center flexDir={"column"}>
          <Text fontSize={"2vw"} pb={"10px"}>
            Board Members
          </Text>
          <HStack w={"max-content"} justifyContent={"space-between"}>
            <VStack alignItems={"flex-start"} pl={"3"} pr={"3"}>
              <Text>Kome Enwa (Chair)</Text>
              <Text>Jody Kirton (Treasurer)</Text>
              <Text>Millie Giebelhaus (Secretary)</Text>
              <Text>Jeanne Maksymchuk (Director)</Text>
            </VStack>
            <VStack alignItems={"flex-start"} pl={"3"} pr={"3"}>
              <Text>Ashley Rinke (Treasurer)</Text>
              <Text>Jan Lehmann (Assistant chairperson)</Text>
              <Text>Tracy Rites (Director)</Text>
            </VStack>
          </HStack>
        </Center>
      </Box>
    </Box>
  );
};

export default Footer;
