import React from 'react';
import { VStack,Text,Stack } from '@chakra-ui/react';
import styled from 'styled-components'

const ServicesSection = () => {
  const StyledOrderedList = styled.ul`
  columns: 2 auto;
  padding-top: 2%;
  @media (max-width: 768px) {
    columns: 1 auto;
  }


  `
  return (
    <Stack minH={['','50vw']} pt={['3rem','']} pb={['','4rem']}>
        <VStack color='white' display='flex' justifySelf='center'alignSelf='center' width={'80vw'} alignItems={['center','flex-start']}>
            <Text fontSize={['2rem','3vw']} pb={[2,5]} pt={[2,0]} fontWeight='bold'>Services</Text>
            <Text fontSize={['1rem','2vw']} textAlign={['center','start']}>What Services Do We Provide To The Community?</Text>
            <Stack display='flex' fontSize={['1rem','1.75vw']} pr={4} pl={4}>
                <StyledOrderedList gridColumn={'auto'} paddingTop='10px'>
                    <li style={{paddingTop: '5px'}}>Immediate 24/7 Crisis Intervention and Support</li>
                    <li style={{paddingTop: '5px'}}>Court Orientation/Preparation</li>
                    <li style={{paddingTop: '5px'}}>Court Accompaniment</li>
                    <li style={{paddingTop: '5px'}}>Information About Protection Orders</li>
                    <li style={{paddingTop: '5px'}}>Help With EPO Applications</li>
                    <li style={{paddingTop: '5px'}}>Victim Impact Statements</li>
                    <li style={{paddingTop: '5px'}}>Requests for Restitution</li>
                    <li style={{paddingTop: '5px'}}>Victim Assistance Applications </li>
                    <li style={{paddingTop: '5px'}}>Help With Applications to CSC and PBC Liaison between agencies and clients such as Zebra Center, Crown, Red Cross, Alberta Support, CFS and RCMP </li>
                    <li style={{paddingTop: '5px'}}>Referrals to local and urban agencies </li>
                </StyledOrderedList>
            </Stack>
        </VStack>
    </Stack>
  )
}

export default ServicesSection