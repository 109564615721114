import React from "react";
import { Box, VStack, Image, Text, Button } from "@chakra-ui/react";
import Logo from "./assets/logo.png";
import NaviBar from "./NaviBar";
import HeroImg from "./assets/hannah-busing-Zyx1bK9mqmA-unsplashCompressed.png";
const HeroSection = () => {
  return (
    <Box
      position="relative"
      bgSize="cover"
      height={"100vh"}
      maxWidth={"4000px"}
      bgRepeat="no-repeat"
      bgPosition="center"
      //bgImage="url('../public/heroimg.png')"
      backgroundImage={HeroImg}
    >
      <VStack color="white" zIndex={1}>
        <Box width="95vw" display="flex" justifyContent="end">
          <NaviBar />
        </Box>
        <Image
          src={Logo}
          position="absolute"
          left={["60%", "0px"]}
          h="100px"
          m={"20px !important"}
        />
        <VStack
          left={["", "10vw"]}
          bottom={["10em", "10vw"]}
          position="absolute"
          p={[4, "1vw"]}
          ml={400}
          w={["", "45vw"]}
        >
          <Text
            fontSize={["2rem", "3vw"]}
            textAlign={["center", "left"]}
            fontWeight="extrabold"
          >
            Crisis Association of Vegreville (CAV)
          </Text>
          <Text fontSize={["1rem", "1.25vw"]} textAlign={["center", "left"]}>
            We provide a non-biased, supportive & respectful services to any
            person who are in a crisis situation. Our primary focus is, but not
            limited to, individuals, couples or families who have been
            traumatized by abuse
          </Text>
          <Button
            href="tel:+17806037381"
            bgColor="#EC7B13"
            alignSelf={["center", "flex-start"]}
            height={["3rem", "3rem"]}
            width={["10rem", "10rem"]}
          >
            <a href="tel:+17806037381">780-603-7381</a>
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default HeroSection;
