import React from 'react';
import {Box, HStack, VStack, Text, Image} from '@chakra-ui/react';
import CourtRoomImage from './assets/court_room2.png'

const CourtSupportSection = () => {
  return (
    <Box minHeight={['','70vw']} display='flex' alignItems='center' justifyContent='center' pt={['3rem','']}>
        <HStack color='white'justifySelf='center'alignSelf='center' width={'80vw'} alignItems={['center','center']} justifyContent='center'>
            <VStack alignItems={['center','flex-start']}>
                <Text fontSize={['2rem','3vw']} pb={[2,5]} pt={[2,0]} fontWeight={'bold'}>Court Support</Text>
                <Text fontSize={['1rem','1.75vw']} textAlign={['center','start']} p={1} mb={'5rem'}>We provide the client/victim with updates about the progression of their file through the justice proceedings.
                  <br></br>                
                  Once the client has been subpoenaed to testify at a trial we meet one–on-one and complete court preparation.
                  <br></br>
                  On the day of the trial we go with them to the court house, either Vegreville or Edmonton's King’s Bench and provide emotional support, liaise with the crown prosecution and support them while they testify.
                  <br></br>
                  We will also provide them with an application for reimbursement of food, travel and accommodations if appropriate
                </Text>
            </VStack>
            <Image src={CourtRoomImage} display={['none','block']} maxWidth='35vw' p='2rem'/>
        </HStack>
    </Box>
  )
}

export default CourtSupportSection